import { getHistoryPurchases } from "endpoints/giftCards";
import { useInfiniteQuery } from "react-query";
import { ToastContext } from "context";
import { useContext, useEffect } from "react";

const useRewards = () => {
  const triggerToast = useContext(ToastContext);
  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteQuery(
    "getRewards",
    ({ pageParam = 1 }) =>
      getHistoryPurchases(pageParam, "UNUSED").then((res) => res?.data),
    {
      getNextPageParam: (lastPage) => {
        const maxPages = lastPage?.meta?.last_page;
        const nextPage = lastPage?.meta?.next_page;
        return nextPage <= maxPages ? nextPage : undefined;
      },
      onError: (error) => {
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      },
    }
  );

  useEffect(() => {
    let fetching = false;
    const onScroll = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } =
        event.target.scrollingElement;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetching = true;
        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  return {
    data: data || { pages: [{ data: [], meta: {} }] },
    isLoading,
    triggerToast,
  };
};
export default useRewards;
