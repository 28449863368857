import { getProfile } from "endpoints/profile";
import { setProfileDetails } from "store/modules/profile";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const useRootProperties = () => {
  const dispatch = useDispatch();
  const { phone } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = () => {
    if (phone !== null) return;
    getProfile().then((response) => {
      dispatch(setProfileDetails(response?.data?.data));
    });
  };

  return null;
};
export default useRootProperties;
