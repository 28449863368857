import { useSelector } from "react-redux";

const useDashboard = () => {
  const { first_name, last_name, loyalty_wallet } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const { brand_name } = useSelector(
    (state) => state.businessDetailsreducer.businessDetails
  );

  return {
    first_name,
    last_name,
    loyalty_wallet,
    brand_name,
  };
};
export default useDashboard;
