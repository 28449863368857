import { useSelector } from "react-redux";
import useRootProperties from "hooks/useRootProperties";

const useAppLayout = () => {
  useRootProperties();
  const { phone, loyalty_wallet } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const { brand_logo_url } = useSelector(
    (state) => state.businessDetailsreducer.businessDetails
  );

  return {
    phone,
    loyalty_wallet,
    brand_logo_url,
  };
};
export default useAppLayout;
