import httpClient from "axiosBase/axiosHandler";
import authHttpClient from "axiosBase/authHandler";

export const createProfile = (data) => {
  const response = authHttpClient.patch(
    `/loyalty/customer-profile/${data?.others?.loyalty_id}/`,
    data?.data,
    {
      headers: {
        Authorization: `Bearer ${data?.others?.token}`,
      },
    }
  );
  return response;
};

export const updateProfile = (data) => {
  const response = httpClient.patch(
    `/loyalty/customer-profile/${data?.others?.loyalty_id}/`,
    data.data
  );
  return response;
};

export const getProfile = () => {
  const response = httpClient.get("/loyalty/customer-profile/");
  return response;
};
