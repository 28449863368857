import { useContext, useState } from "react";
import { loginUser } from "endpoints/auth";
import { useMutation } from "react-query";
import { RequestLoader, ToastContext } from "context";
import { getRandomIntInclusive, getSlugName } from "helpers";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const useLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const { isLoading, mutate: login } = useMutation((data) => loginUser(data));
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const { brand_name } = useSelector(
    (state) => state.businessDetailsreducer.businessDetails
  );

  const handleLogin = () => {
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    login(
      {
        phone_number: `+234${phoneNumber}`,
        business_slug: getSlugName(),
      },
      {
        onSuccess: (response) => {
          setRequestLoaderProgress(100);
          navigate({
            pathname: "/login/otp",
            search: createSearchParams({
              session_id: response?.data?.data?.id,
              access_number: phoneNumber,
            }).toString(),
          });
        },
        onError: (error) => {
          setRequestLoaderProgress(100);
          if (error?.response?.status === 400)
            triggerToast(error?.response?.data?.detail, "warning");
        },
      }
    );
  };

  return { phoneNumber, isLoading, brand_name, setPhoneNumber, handleLogin };
};
export default useLogin;
