import { useNavigate, useParams } from "react-router-dom";
import { getGiftCardsDetails, purchaseGiftCard } from "endpoints/giftCards";
import { useMutation, useQuery } from "react-query";
import { useContext } from "react";
import { RequestLoader, ToastContext } from "context";
import { getRandomIntInclusive } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "endpoints/profile";
import { setProfileDetails } from "store/modules/profile";

const useGiftCardDetails = () => {
  const param = useParams();
  const { brand_name, term_and_condition_url, brand_logo_url } = useSelector(
    (state) => state.businessDetailsreducer.businessDetails
  );
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, isFetching } = useQuery(
    ["getGiftCardsDetails"],
    () => getGiftCardsDetails(param.id).then((res) => res?.data.data),
    {
      onError: (error) => {
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      },
    }
  );

  const { isLoading, mutate: buyNow } = useMutation((data) =>
    purchaseGiftCard(data)
  );

  const buyGiftCard = () => {
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    buyNow(
      {
        id: param.id,
      },
      {
        onSuccess: (response) => {
          setRequestLoaderProgress(100);
          const responses = response?.data?.data;
          getProfile().then((response) => {
            dispatch(setProfileDetails(response?.data?.data));
          });
          navigate(
            `/app/gift-cards/${param.id}/success?redemption_code=${responses?.redemption_code}&amount=${responses?.giftcard_naira_value}`
          );
        },
        onError: (error) => {
          setRequestLoaderProgress(100);
          if (error?.response?.status === 400)
            triggerToast(error?.response?.data?.detail, "warning");
        },
      }
    );
  };

  return {
    isFetching,
    brand_name,
    term_and_condition_url,
    brand_logo_url,
    isLoading,
    data,
    buyGiftCard,
  };
};
export default useGiftCardDetails;
