import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const BioSchema = yup
  .object({
    firstName: yup
      .string()
      .required("Name is required")
      .max(30, "Maximum of 30 characters is allowed")
      .min(3, "Minimum of 3 characters is allowed"),
    lastName: yup
      .string()
      .required("Last Name is required")
      .max(30, "Maximum of 30 characters is allowed")
      .min(3, "Minimum of 3 characters is allowed"),
    middleName: yup.string().max(30, "Maximum of 30 characters is allowed"),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address")
      .max(40, "Maximum of 40 characters is allowed"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format"),
    // gender: yup.string().required("Gender is required"),
    dob: yup.string().required("Date of birth is required"),
  })
  .required();

const BioValidation = (
  firstName,
  lastName,
  middleName,
  email,
  phoneNumber,
  dob,
  gender
) => {
  return useForm({
    resolver: yupResolver(BioSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      firstName,
      lastName,
      middleName,
      email,
      phoneNumber,
      dob,
      gender,
    },
  });
};

export default BioValidation;
