import { useState, useContext, useEffect } from "react";
import { RequestLoader, ToastContext } from "context";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { getRandomIntInclusive } from "helpers";
import { loginOtp } from "endpoints/auth";
import { useMutation } from "react-query";

const useLoginVerification = () => {
  const [otp, setOtp] = useState("");
  const params = useSearchParams()[0];
  const sessionId = params.get("session_id");
  const accessNumber = params.get("access_number");
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();
  const { isLoading: isVerifing, mutate: loginVerification } = useMutation(
    (data) => loginOtp(data)
  );

  useEffect(() => {
    if (!sessionId || !accessNumber) return navigate("/login");
  }, []);

  const handleVerification = (value) => {
    setOtp(value);
    if (value.length !== 4) return;
    setRequestLoaderProgress(getRandomIntInclusive(3, 30));
    loginVerification(
      { code: value, id: sessionId },
      {
        onSuccess: (response) => {
          setRequestLoaderProgress(100);
          if (response?.data?.data?.profile_completed) {
            localStorage.setItem(
              `${process.env.REACT_APP_ACCESS_TOKEN_NAME}`,
              response?.data?.data?.access_token
            );
            return navigate("/app");
          }
          navigate({
            pathname: "/customer-bio",
            search: createSearchParams({
              token: response?.data?.data?.access_token,
              access_number: accessNumber,
            }).toString(),
          });
        },
        onError: (error) => {
          setRequestLoaderProgress(100);
          if (error?.response?.status === 400)
            triggerToast(error?.response?.data?.detail, "warning");
        },
      }
    );
  };

  return { handleVerification, otp, isVerifing };
};
export default useLoginVerification;
