import React from "react";
import {
  MetaSetter,
  RequestLoaderTrigger,
} from "components/secondaryComponents";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components/primaryComponents";
import styles from "./Settings.module.scss";
import { logoutHandler } from "helpers";
import { useSelector } from "react-redux";

const Settings = () => {
  const navigate = useNavigate();
  const { first_name, last_name, email } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );

  return (
    <MetaSetter
      title="Lumi Merchant Loyalty | Settings"
      description="Adjust your account settings"
    >
      <RequestLoaderTrigger>
        <motion.div
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          className={styles.settings}
        >
          <Link to="/app">
            <FontAwesomeIcon
              className="dark--text"
              icon="angle-left"
              style={{ fontSize: "22px" }}
            />
          </Link>

          <div className={styles.links}>
            <div
              className={styles.links__item}
              onClick={() => navigate("/app/profile")}
            >
              <div className="d-flex align-items-center">
                <div className={styles.avatar}>
                  {first_name.charAt(0)}
                  {last_name.charAt(0)}
                </div>
                <div>
                  <h6 className="mb-0">
                    {first_name} {last_name}
                  </h6>
                  <p className="small-text-size">{email}</p>
                </div>
              </div>
              <FontAwesomeIcon
                className="dark--text"
                icon="angle-right"
                style={{ fontSize: "20px" }}
              />
            </div>
          </div>

          <Button
            classNames="btn btn--outline-red cover mt-auto"
            onClick={logoutHandler}
          >
            Log Out
          </Button>
        </motion.div>
      </RequestLoaderTrigger>
    </MetaSetter>
  );
};
export default Settings;
