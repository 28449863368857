import { useContext, useState } from "react";
import { createProfile, updateProfile } from "endpoints/profile";
import { useMutation } from "react-query";
import { RequestLoader, ToastContext } from "context";
import { getCustomerLoyaltyId, getRandomIntInclusive } from "helpers";

const useCustomerBioData = ({ type, data, onSuccessCall }) => {
  const token = data?.token;
  const accessNumber = data?.phoneNumber;
  const [firstName, setFirstName] = useState(data?.firstName);
  const [lastName, setLastName] = useState(data?.lastName);
  const [middleName, setMiddleName] = useState(data?.middleName);
  const [email, setEmail] = useState(data?.email);
  const [phoneNumber, setPhoneNumber] = useState(
    accessNumber ? accessNumber : ""
  );
  const [gender, setGender] = useState(data?.gender);
  const [dob, setDob] = useState(data?.dob);
  const { isLoading, mutate: updateBio } = useMutation((data) => {
    if (type === "create") createProfile(data);
    else updateProfile(data);
  });
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);

  const triggerBioUpdate = () => {
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    updateBio(
      {
        data: {
          first_name: firstName,
          last_name: lastName,
          middle_name: middleName === "" ? null : middleName,
          email: email,
          phone: `+234${phoneNumber}`,
          gender: gender,
          date_of_birth: dob,
        },
        others: {
          loyalty_id: getCustomerLoyaltyId(token),
          token: token,
        },
      },
      {
        onSuccess: () => {
          setRequestLoaderProgress(100);
          onSuccessCall();
        },
        onError: (error) => {
          setRequestLoaderProgress(100);
          if (error?.response?.status === 400)
            triggerToast(error?.response?.data?.detail, "warning");
        },
      }
    );
  };

  return {
    isLoading,
    firstName,
    lastName,
    middleName,
    email,
    phoneNumber,
    gender,
    dob,
    setFirstName,
    setLastName,
    setMiddleName,
    setEmail,
    setPhoneNumber,
    setGender,
    setDob,
    triggerBioUpdate,
  };
};
export default useCustomerBioData;
