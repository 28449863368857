import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import styles from "./AppLayout.module.scss";
import Logos from "assets/logos";
import icons from "assets/icons";
import { motion } from "framer-motion";
import useAppLayout from "./useAppLayout";
import { formatPoints } from "helpers";

export const AppLayout = () => {
  const navigate = useNavigate();
  const { phone, loyalty_wallet, brand_logo_url } = useAppLayout();

  return (
    <>
      {phone !== null && (
        <div className={styles.appLayout}>
          <motion.div
            className={styles.appLayout__header}
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <div className={styles.appLayout__header__points}>
              <span>{formatPoints(loyalty_wallet.balance)}</span>
            </div>
            <img
              className={styles.appLayout__header__logo}
              src={
                brand_logo_url == null ? Logos.secondaryLogo : brand_logo_url
              }
              alt="lumi merchant"
              onClick={() => navigate("/app")}
            />
            <Link to="/app/settings">
              <img src={icons.gearIcon} alt="gear" />
            </Link>
          </motion.div>

          <div className={styles.appLayout__content}>
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};
