import React from "react";
import {
  LoaderHelper,
  MetaSetter,
  RequestLoaderTrigger,
} from "components/secondaryComponents";
import { Button } from "components/primaryComponents";
import { motion } from "framer-motion";
import styles from "./GiftCardDetails.module.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import icons from "assets/icons";
import useGiftCardDetails from "./useGiftCardDetails";
// import { formatCurrency } from "helpers";
import images from "assets/images";

const GiftCardDetails = () => {
  const {
    brand_name,
    term_and_condition_url,
    data,
    isFetching,
    isLoading,
    buyGiftCard,
  } = useGiftCardDetails();

  return (
    <MetaSetter
      title="Lumi Merchant Loyalty | Gift card purchase"
      description="Buy gift cards from point earned"
    >
      <RequestLoaderTrigger>
        <div className={styles.giftCardDetails}>
          <Link to="/app/gift-cards">
            <FontAwesomeIcon
              className="dark--text"
              icon="angle-left"
              style={{ fontSize: "22px" }}
            />
          </Link>
          <LoaderHelper classNames="mt-5" isLoading={isFetching}>
            <motion.div initial={{ y: 50 }} animate={{ y: 0 }} className="pt-3">
              <div className={styles.brandCard}>
                <img
                  src={images.giftCardimage}
                  className={styles.giftCardimage}
                  alt="brand"
                />

                <div>
                  <h4
                    style={{ fontSize: "20px" }}
                    className="font-weight-semibold mb-1"
                  >
                    {brand_name}
                  </h4>
                </div>
              </div>

              <div className="mb-4" style={{ maxWidth: "220px" }}>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h6 className="smallest-text-size font-weight-semibold mb-0 mr-3">
                    Points required:
                  </h6>
                  <div className={styles.points}>
                    <span>{data?.amount_in_points}pts</span>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-between align-items-center mb-2 ">
                  <h6 className="smallest-text-size font-weight-semibold mb-0 mr-3">
                    Gift card value:
                  </h6>
                  <h6 className="smallest-text-size mb-0">
                    {formatCurrency(data?.amount_in_naira)}
                  </h6>
                </div> */}
                <div className="d-flex justify-content-between align-items-center ">
                  <h6 className="smallest-text-size font-weight-semibold mb-0 mr-3">
                    Redemption period:
                  </h6>
                  <p className="smallest-text-size purple--text mb-0">
                    {data?.redemption_period}days
                  </p>
                </div>
              </div>

              <div>
                <h4 className="font-weight-semibold">Redemption Details</h4>
                <div className={styles.descriptionCard}>
                  <div className={styles.gift}>
                    <img
                      src={icons.giftIcon}
                      alt="gift"
                      width="16px"
                      height="16px"
                    />
                  </div>
                  <div className="pt-2">
                    <h6 className="font-weight-semibold mb-2">
                      Read Carefully
                    </h6>
                    <p className="small-text-size">
                      <span className="purple--text">1. </span>Visit any of our
                      outlets nationwide to redeem your gift card
                      <br />
                      <span className="purple--text">2. </span>No Return or
                      refund for gift cards <br />
                      <span className="purple--text">3. </span>Gift cards can
                      only be used to purchase eligible goods and service
                      <br />
                      <span className="purple--text">4. </span>Gift card must be
                      redeemed within redemption period
                      <br />
                      <span className="purple--text">5. </span>Other terms and
                      conditions may apply
                    </p>
                  </div>
                </div>
              </div>
              <p className="mt-3 text-center small-text-size dark--text">
                To complete this offer, you must meet the requirements listed
                above.{" "}
                <a
                  href={term_and_condition_url}
                  target="_blank"
                  rel="noreferrer"
                  className="purple--text small-text-size text-underline"
                >
                  Terms and Conditions
                </a>
              </p>

              <motion.div
                className={styles.buyGift}
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <Button
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  onClick={buyGiftCard}
                  loadingText="Processing..."
                  classNames="btn btn--primary cover"
                >
                  Buy Giftcard
                </Button>
              </motion.div>
            </motion.div>
          </LoaderHelper>
        </div>
      </RequestLoaderTrigger>
    </MetaSetter>
  );
};
export default GiftCardDetails;
