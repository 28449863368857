import axios from "axios";
import store from "../store";
import { setApiErrorMessage } from "../store/modules/apiErrorHandler";
import { logoutHandler, getUrlQuerysection } from "../helpers";

// base url
// eslint-disable-next-line no-undef
const baseUrlBusiness = process.env.REACT_APP_LUMI_MERCHANT_APP_BASE_URL;

const httpClient = axios.create({
  baseURL: baseUrlBusiness,
});

// setting authorization header
const httpRequest = (config) => {
  if (localStorage.getItem(`${process.env.REACT_APP_ACCESS_TOKEN_NAME}`))
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      process.env.REACT_APP_ACCESS_TOKEN_NAME
    )}`;

  return config;
};

// Response interceptors
const onFulfilled = (response) => Promise.resolve(response);

const onRejected = async (error) => {
  if (error?.response) {
    if (error?.response?.status) {
      if (error?.response?.status === 401) {
        localStorage.setItem(
          "nextRoute",
          `${window.location.pathname}${getUrlQuerysection()}`
        );
        logoutHandler();
      }
      if (error?.response?.status === 403) {
        // Do something
        store.dispatch(
          setApiErrorMessage(
            "You don't have the permission to perform this action"
          )
        );
      }
      if (error.response.status === 500 || error.response.status === 503) {
        // Do something
        store.dispatch(
          setApiErrorMessage("Something went wrong, please try again")
        );
      }
      if (error.response.status === 404) {
        // Do something
        store.dispatch(setApiErrorMessage("This resource was not found"));
      }

      if (error.response.status === 405) {
        // Do something
        store.dispatch(setApiErrorMessage("Method not allowed"));
      }
    }
  }

  if (!navigator.onLine) {
    store.dispatch(
      setApiErrorMessage(
        "No internet connection, please check your internet connectivity"
      )
    );
  }

  return Promise.reject(error);
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(httpRequest);
/** Adding the response interceptors */
httpClient.interceptors.response.use(onFulfilled, onRejected);

export default httpClient;
