import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./History.module.scss";
import { NavLink, Outlet, useLocation } from "react-router-dom";

const History = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: 50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -50, opacity: 0 }}
        className={styles.history}
      >
        <div className={styles.history__tab}>
          <NavLink
            to="earnings"
            className={[
              styles.tabItem,
              `${
                pathname.includes("/app/history/earnings") ? styles.active : ""
              }`,
            ].join(" ")}
          >
            Points Earned
          </NavLink>
          <NavLink
            to="purchases"
            className={[
              styles.tabItem,
              `${
                pathname.includes("/app/history/purchases") ? styles.active : ""
              }`,
            ].join(" ")}
          >
            Points Spent
          </NavLink>
        </div>
        <div className={styles.history__content}>
          <Outlet />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
export default History;
